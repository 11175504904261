import { BILLING_ENTITY_TYPE_COMPANY } from "@/constants/billingInformations";
import { PHONE_TYPE_MOBILE } from "@/constants/common";

export default {
  type: "establishments",
  establishment_type: null,
  fiscal_number: null,
  customer_number: null,
  excerpt: "",
  company_name: null,
  email: null,
  phone: null,
  phone_extension: null,
  phone_type: PHONE_TYPE_MOBILE,
  other_phones: [],
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  has_warehouse: false,
  has_folder: true,
  relationshipNames: [
    "organization",
    "tags",
    "allowedLocations",
    "serviceCenter",
    "coordinator",
    "managers",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  customer: {
    type: "customers",
    id: null,
  },
  tags: [],
  allowedLocations: [],
  coordinator: {
    type: "users",
    id: null,
  },
  managers: [],

  billing_entity_type: BILLING_ENTITY_TYPE_COMPANY,
  billing_company_name: null,
  billing_firstname: null,
  billing_lastname: null,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
};
