<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="establishment.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            establishment.organization.id = organizationId;
            establishment.serviceCenter.id = null;
            establishment.allowedLocations = [];
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="establishment.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="establishment.organization.id"
        @locationsChanged="
          (locations) => {
            establishment.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <!-- Establishment type -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('ESTABLISHMENTS.SCHOOL_LEVEL')} (*)`"
          :placeholder="$t('ESTABLISHMENTS.SCHOOL_LEVEL')"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
          <el-select
            :name="`${$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE')}`"
            :placeholder="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE')"
            v-model="establishment.establishment_type"
          >
            <el-option
              v-for="(establishment, key) in ESTABLISHMENTS_TYPES"
              :key="key"
              :value="establishment"
              :label="$t('ESTABLISHMENTS.ESTABLISHMENT_TYPE_' + establishment)"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.establishment_type" />
      </div>

      <div class="col">
        <base-input
          :label="`${$t('COMMON.SERVICECENTER')} (*)`"
          :placeholder="$t('COMMON.SERVICECENTER')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <serviceCenter-selector
            :serviceCenter="establishment.serviceCenter.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="establishment.organization.id"
            :disabled="!establishment.organization.id"
            @serviceCenterChanged="
              (serviceCenterId) => {
                establishment.serviceCenter.id = serviceCenterId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.serviceCenter" />
      </div>
    </div>

    <!-- Company name -->
    <base-input
      :label="`${$t('COMMON.COMPANY_NAME')} (*)`"
      v-model="establishment.company_name"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.company_name" />

    <div class="row">
      <div class="col">
        <!-- Customer number -->
        <base-input
          :label="`${$t('COMMON.CUSTOMER_NUMBER')}`"
          v-model="establishment.customer_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.customer_number" />
      </div>

      <div class="col">
        <!-- Fiscal number -->
        <base-input
          :label="`${$t('COMMON.FISCAL_NUMBER')}`"
          v-model="establishment.fiscal_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.fiscal_number" />
      </div>
    </div>

    <!-- Email address -->
    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      v-model="establishment.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- Phone number -->
    <div class="row">
      <div class="col-2">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="establishment.phone_type"
            @change="
              (type) => {
                establishment.phone_type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-7">
        <base-input :label="`${$t('COMMON.PHONE')}`">
          <phone-number-input
            :phoneNumber="establishment.phone"
            @phoneNumberChanged="
              (phone) => {
                establishment.phone = phone;
                onFormChanged();
              }
            "
            :required="false"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>
      <div class="col-3">
        <base-input
          :label="`${$t('COMMON.EXTENSION')}`"
          v-model="establishment.phone_extension"
          @change="
            () => {
              onFormChanged();
            }
          "
          :inputClasses="'extension-input'"
        />
        <validation-error :errors="apiValidationErrors.phone_extension" />
      </div>
    </div>

    <!-- Other phone numbers -->
    <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
      <phone-numbers-selector
        :phoneNumbers="establishment.other_phones"
        @phoneNumbersChanged="
          (phoneNumbers) => {
            establishment.other_phones = phoneNumbers;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.other_phones" />

    <div class="row">
      <!-- Country -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COUNTRY')} (*)`"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="establishment.country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                establishment.country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>

      <!-- State  -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATE')} (*)`"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="establishment.country"
            :state="establishment.state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                establishment.state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.state" />
      </div>

      <!-- City -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CITY')} (*)`"
          v-model="establishment.city"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.city" />
      </div>

      <!-- ZIP Code  -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ZIPCODE')} (*)`"
          v-model="establishment.zipcode"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>
    </div>

    <!-- Address -->
    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      v-model="establishment.address"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.address" />

    <!-- Tags -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.TAGS')}`"
          :placeholder="`${$t('COMMON.TAGS')}`"
        >
          <tags-selector
            :tags="establishment.tags"
            @tagsChanged="
              (tags) => {
                establishment.tags = tags;
                onFormChanged();
              }
            "
            :disabled="!establishment.organization.id"
            :organization="establishment.organization.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.tags" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('ESTABLISHMENTS.COORDINATOR')} (*)`"
          :placeholder="`${$t('ESTABLISHMENTS.COORDINATOR')}`"
        >
          <user-selector
            :user="establishment.coordinator.id"
            @userChanged="
              (userId) => {
                establishment.coordinator.id = userId;
                onFormChanged();
              }
            "
            :organization="establishment.organization.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.coordinator" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('ESTABLISHMENTS.MANAGERS')}`"
          :placeholder="`${$t('ESTABLISHMENTS.MANAGERS')}`"
        >
          <users-selector
            :users="establishment.managers"
            @usersChanged="
              (users) => {
                establishment.managers = users;
                onFormChanged();
              }
            "
            :organization="establishment.organization.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.users" />
      </div>
    </div>

    <div class="">
      <base-input
        :label="`${$t('COMMON.NOTE')}`"
        :placeholder="$t('COMMON.NOTE')"
      >
        <html-editor v-model="establishment.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <base-checkbox
      v-model="establishment.has_warehouse"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
      :disabled="!canCheckWarehouse()"
    >
      <span class="form-control-label">
        {{ $t("COMMON.HAS_WAREHOUSE") }}
      </span>
    </base-checkbox>

    <base-checkbox
      v-model="establishment.has_folder"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("COMMON.HAS_FOLDER") }}
      </span>
    </base-checkbox>

    <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>

    <base-checkbox
      v-model="billingAddressSameAsEstablishment"
      class="mb-3"
      @input="applyEstablishmentDataToBilling"
    >
      <span class="form-control-label">
        {{ $t("ESTABLISHMENTS.BILLING_INFORMATIONS_SAME_AS_ESTABLISHMENT") }}
      </span>
    </base-checkbox>

    <billing-informations-form
      v-if="!billingAddressSameAsEstablishment"
      :billingInformationsData="establishment"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          establishment.id
            ? $t("ESTABLISHMENTS.EDIT_ESTABLISHMENT")
            : $t("ESTABLISHMENTS.ADD_ESTABLISHMENT")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import { BILLING_ENTITY_TYPE_COMPANY } from "@/constants/billingInformations";
import formMixin from "@/mixins/form-mixin";
import { ESTABLISHMENTS_TYPES } from "@/constants/establishments";
import { phoneTypesOptions } from "@/constants/common";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import ServiceCenterSelector from "@/components/ServiceCenterSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    CountrySelector,
    StateSelector,
    OrganizationSelector,
    PhoneNumberInput,
    TagsSelector,
    BillingInformationsForm,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    ServiceCenterSelector,
    PhoneNumbersSelector,
    UserSelector,
    UsersSelector,
    HtmlEditor,
  },

  mixins: [formMixin],

  props: ["establishmentData", "formErrors", "loading"],

  data() {
    let establishmentData = { ...this.establishmentData };
    establishmentData = this.$fillUserOrganizationData(establishmentData);
    establishmentData = this.$fillUserCafeteriasData(establishmentData);

    return {
      establishment: establishmentData,
      billingAddressSameAsEstablishment: false,
      ESTABLISHMENTS_TYPES: ESTABLISHMENTS_TYPES,
      phoneTypesOptions,
    };
  },

  methods: {
    async handleSubmit() {
      let establishmentData = cloneDeep(this.establishment);
      establishmentData = this.$fillUserOrganizationData(establishmentData);
      establishmentData = this.$fillUserCafeteriasData(establishmentData);

      this.$emit("establishmentSubmitted", establishmentData);
    },

    billingInformationsChanged(billingInformations) {
      this.establishment = { ...this.establishment, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      if (this.billingAddressSameAsEstablishment) {
        this.establishment.billing_entity_type = BILLING_ENTITY_TYPE_COMPANY;
        this.establishment.billing_company_name =
          this.establishment.company_name;
        this.establishment.billing_firstname = this.establishment.firstname;
        this.establishment.billing_lastname = this.establishment.lastname;
        this.establishment.billing_country = this.establishment.country;
        this.establishment.billing_state = this.establishment.state;
        this.establishment.billing_city = this.establishment.city;
        this.establishment.billing_zipcode = this.establishment.zipcode;
        this.establishment.billing_address = this.establishment.address;
        this.establishment.billing_email = this.establishment.email;
        this.establishment.billing_firstname = "N/A";
        this.establishment.billing_lastname = "N/A";
      }
      this.$emit("formChanged");
    },

    applyEstablishmentDataToBilling(value) {
      this.onFormChanged();
    },

    canCheckWarehouse() {
      if (!this.establishmentData) {
        return true;
      }
      if (!this.establishmentData.id) {
        if (this.$currentUserCan(this.$permissions.PERM_CREATE_WAREHOUSES)) {
          return true;
        }
      }
      if (!this.establishmentData.has_warehouse) {
        if (this.$currentUserCan(this.$permissions.PERM_CREATE_WAREHOUSES)) {
          return true;
        }
      }
      if (this.establishmentData.has_warehouse) {
        if (this.$currentUserCan(this.$permissions.PERM_DELETE_WAREHOUSES)) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    establishmentData(establishmentData) {
      if (establishmentData) {
        this.establishment = {
          ...this.establishment,
          ...cloneDeep(establishmentData),
        };
      }
    },
  },
};
</script>
