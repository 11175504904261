export const ESTABLISHMENT_TYPE_ELEMENTARY = "ELEMENTARY";
export const ESTABLISHMENT_TYPE_SECONDARY = "SECONDARY";
export const ESTABLISHMENT_TYPE_COLLEGE = "COLLEGE";
export const ESTABLISHMENT_TYPE_UNIVERSITY = "UNIVERSITY";
export const ESTABLISHMENT_TYPE_PROFESSIONAL = "PROFESSIONAL";
export const ESTABLISHMENTS_TYPES = [
  ESTABLISHMENT_TYPE_ELEMENTARY,
  ESTABLISHMENT_TYPE_PROFESSIONAL,
  ESTABLISHMENT_TYPE_SECONDARY,
  ESTABLISHMENT_TYPE_COLLEGE,
  ESTABLISHMENT_TYPE_UNIVERSITY,
];
